.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	@media (max-width: 991px) {
		margin-top: 35px;
	}

	.inner-banner {
		&.kontact {
			background-image: url(RESOURCE/img/banner-kontakt.jpg);
		}
	}
}