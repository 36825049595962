.searchbar-v1 {
	position: relative;
	top: -90px;
	width: 100%;

	@media (max-width: 576px) {
		padding: 0px 10px;
	}


	.title {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;

	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--white);
		border: 1px solid #eaeaea;
		flex-flow: wrap;
		position: relative;
		border-top: 0;
		border-radius: 0 0 5px 5px;
		/*box-shadow: 0 2px 12px rgb(254 154 0 / 25%);*/

		.seacrbar-top {
			width: 100%;
			display: flex;
			align-items: center;
			padding: 0 20px 0 0;
			flex-wrap: wrap;
		}

		.advance-filer {
			width: 100%;
			padding: 10px 22px;
			border-top: 1px solid #eaeaea;

			@media (max-width: 767px) {
				padding: 10px 12px;
				border-top: 0px solid #eaeaea;
			}
		}


		.form-bx-area {
			position: relative;
		}

		#unitgroup-id {
			select {
				-webkit-appearance: none;
				-moz-appearance: none;
				text-indent: 1px;
				text-overflow: '';
			}
		}

		.form-flex {
			width: 30%;
			padding: 20px 22px;
			border-right: 1px solid #eaeaea;

			&.v2 {
				width: 45%;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;

				&.v2 {
					width: 100%;
				}
			}

			.fa {
				position: absolute;
				right: 0;
				top: 12px;

				@media (max-width: 767px) {
					top: 12px;
				}
			}

			.form-control {
				padding: 5px 0;
				background-color: var(--white);
				border: none;
				padding-right: 30px;
				text-align: left;
				height: auto;
				min-height: 40px;
				background-color: transparent;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: none;
				line-height: 20px;
			}

			>label {

				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;
			}


		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;
			position: absolute;
			right: 0;
			top: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
				position: static;
			}

			.btn {
				width: 101%;
				height: 92px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}