.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}

	.backlink {
		vertical-align: bottom;

		@media all and (min-width: 768px) and (max-width: 991px) {
			text-align: left;
		}
	}

}