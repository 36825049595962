.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.modal-custom {
		.modal-custom-dialog {
			height: 690px !important;

			@media (max-width:767px) {
				height: auto !important;
				padding-bottom: 20px;
			}

			.modal-custom-content {
				@media (min-width:993px) {
					height: 610px !important;
				}

				@media (max-width:767px) {
					height: auto !important;
				}

				padding: 0;

				>div {
					width: 100%;
					margin-bottom: 10px;
					position: relative;
				}

				/*.video-container {
					height: 0;
					padding-bottom: 56.25%;
					position: relative;

					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}*/

				.video-hint {
					position: relative;
					background-color: #000;
					aspect-ratio: 16 / 9;
					padding: 20px;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					flex-direction: column;

					@media(max-width:568px) {
						aspect-ratio: inherit;
					}

					.button-line {
						text-align: center;

						.btn {
							margin: 10px 0;
						}
					}
				}

				iframe {
					padding-top: 10px;

					@media (max-width:767px) {
						height: 100% !important;
					}
				}
			}
		}
	}

	.headline {
		font-size: var(--h4-font-size);
		color: var(--color-primary);
		font-weight: 600;
	}

	.show-more-btn {
		text-align: center;
		margin-bottom: 20px;
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}

	.openstreetwarning {
		height: var(--facility-map-height) !important;
		aspect-ratio: inherit;
	}

	.title {
		font-weight: 600;
		font-size: 20px;
		padding-bottom: 10px;
	}

}