.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.video {
		height: 500px;

		@media(max-width:991px) {
			height: 400px;
		}
	}

	.modal-custom-dialog {
		height: auto !important;
		padding-bottom: 20px;
		min-height: 600px;

		@media(max-width:1199px) {
			min-height: auto;
		}

		@media(max-width:767px) {
			min-height: auto;
		}

		.modal-custom-content {
			height: auto !important;

			iframe {
				@media(max-width:767px) {
					height: 100% !important;
				}

			}
		}
	}
}