.facility-gallery-v1 {

	.unit-pr-dt-gallery {
		@media (min-width:993px) {
			display: flex;
			justify-content: space-between;

		}
	}

	.title-img {

		position: relative;
		width: 100%;
		height: 450px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}

	.videoimg-gallery {
		position: relative;
		top: -70px;
		z-index: 1;
		width: 100%;
		display: inline-flex;
		justify-content: flex-end;

		@media (min-width:992px) {
			margin-bottom: -100px;
		}

		@media (max-width:992px) {
			top: -50px;
			height: auto;
			justify-content: center;
		}

		@media (max-width:380px) {
			top: -45px;
		}

		.video-col {
			width: 300px;
			height: 202px;
			border: 3px solid var(--color-white);
			border-radius: var(--border-radius);
			margin-right: 2px;
			box-shadow: 3px 3px 2px rgb(0 0 0 / 10%);
			position: relative;

			@media (max-width:992px) {
				height: 155px;
			}

			@media (max-width:767px) {
				height: 147px;
				/* box-shadow: none; */
				overflow: hidden;
			}

			@media (max-width:480px) {
				height: 103px;
			}


			@media (max-width:375px) {
				height: 80px;
			}

			iframe {
				width: 100%;
				height: 100%;
				border: 0;
				position: absolute;
				top: 0;
				z-index: 1;
			}

			.click-modal {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 5;
				width: 100%;
				border: none;
			}
		}

		/*a:nth-child(2) {
			display: none;
		}*/

		a {
			position: relative;
			display: inline-block;
			border: 3px solid var(--color-white);
			border-radius: var(--border-radius);
			margin-right: 2px;
			box-shadow: 3px 3px 2px rgba(0, 0, 0, .1);
			width: 300px;
			height: 100%;

			@media (max-width:767px) {
				height: 147px;
			}

			@media (max-width:480px) {
				height: 103px;
			}

			@media (max-width:375px) {
				height: 80px;
			}

			/*&:nth-child(2) {
				display: none;
			}*/

			&:nth-child(n + 5) {
				display: none;
			}

			&:nth-child(4) {

				&:before {
					z-index: 5;
					content: "+ mehr";
					position: absolute;
					color: var(--color-white);
					background-color: rgba(0, 0, 0, 0.5);
					width: 100%;
					height: 100%;
					text-align: center;
					display: inline-block;
					padding-top: 25%;
					font-weight: 700;
					font-size: 20px;
				}
			}
		}

		img {
			border: none;
			padding: 0;
			width: 100%;

			@media (max-width:767px) {
				object-fit: cover;
				height: 100%;
			}
		}

		&.hide-secondcol {
			a {
				&:nth-child(2) {
					display: none;
				}
			}
		}

		&.noVideoImg-gallery {
			a {
				&:nth-child(1) {
					display: none;
				}
			}

		}
	}

}